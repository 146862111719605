import React, { useReducer, useState, createContext, useEffect } from "react";
import Div100vh from "react-div-100vh";
import churchImg from "./assets/church.png";
import { makeStyles } from "@material-ui/core";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import { seatsReducer } from "./reducers";

import Header from "./containers/Header";
import SigninModal from "./containers/SigninModal";
import Snack from "./containers/Snack";
import { usePersistedState } from "./libs/customHooks";

import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { Auth, graphqlOperation, API } from "aws-amplify";
import { userBookings } from "./graphql/customs";

Amplify.configure(awsExports);

export const AppContext = createContext();
export const CurrentUserContext = createContext();
export const CurrentChurchContext = createContext();
export const HeaderContext = createContext();
export const UserDialogContext = createContext();
export const OrderContext = createContext();
export const SnackContext = createContext();
export const IsAuthenticatedContext = createContext();

const useStyles = makeStyles({
  fullsizeImage: {
    background: `url(${churchImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "fixed",
    width: "100vw",
    overflow: "auto",
  },
});
function App() {
  const classes = useStyles();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [currentUser, setCurrentUser] = usePersistedState("currentUser", {});
  //const [currentUser, setCurrentUser] = useState({});
  const [currentChurch, setCurrentChurch] = useState();
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [snack, setSnack] = useState({ open: false });
  const [seats, setSeats] = useState();
  const [order, setOrder] = useState(0);
  const [header, setHeader] = useState(() => ({
    logo: true,
    backTo: "/",
    page: "Landing",
    title: "",
    userName: currentUser?.name,
    seats: 0,
  }));

  const initialState = {
    userTotalSeats: 1,
    userBookings: {},
    celebrations: {
      1: {
        id: 1,
        churchId: 1,
        epoch: 1590915600,
        churchName: "Saint-Pothin",
        name: "Dimanche de Pentecôte",
        availableSeats: 300,
      },
      2: {
        id: 2,
        churchId: 1,
        churchName: "Église de l'Immaculée Conception",
        epoch: 1590829200,
        name: "Messe ordinaire",
        availableSeats: 150,
      },
    },
  };

  async function onLoad() {
    try {
      const currentSession = await Auth.currentSession();
      console.log("[currentSession] : ", currentSession);
      setIsAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        console.log(e);
      }
      console.log("[currentSession] : ", e);
    }
    setIsAuthenticating(false);
  }

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    async function getUserSeats() {
      try {
        const resp = await API.graphql(
          graphqlOperation(userBookings, { id: currentUser.id })
        );
        const seats = resp.data.getUser.bookings.items.length;
        setSeats(seats);
      } catch (e) {
        console.log("error fetching user ", e);
      }
    }
    getUserSeats();
    setHeader((header) => ({
      ...header,
      userName: currentUser.name,
      seats,
    }));
  }, [currentUser.name, seats, order]);

  const [state, dispatch] = useReducer(seatsReducer, initialState);

  return (
    !isAuthenticating && (
      <Router>
        <IsAuthenticatedContext.Provider
          value={{ isAuthenticated, setIsAuthenticated }}
        >
          <UserDialogContext.Provider
            value={{ userDialogOpen, setUserDialogOpen }}
          >
            <CurrentUserContext.Provider
              value={{ currentUser, setCurrentUser }}
            >
              <AppContext.Provider value={{ state, dispatch }}>
                <CurrentChurchContext.Provider
                  value={{ currentChurch, setCurrentChurch }}
                >
                  <OrderContext.Provider value={{ order, setOrder }}>
                    <HeaderContext.Provider value={{ header, setHeader }}>
                      <SnackContext.Provider value={{ snack, setSnack }}>
                        <Div100vh className={classes.fullsizeImage}>
                          <Header {...header} />
                          <Snack
                            open={snack.open}
                            setSnack={setSnack}
                            type={snack.type}
                          />
                          <SigninModal
                            open={userDialogOpen}
                            setOpen={setUserDialogOpen}
                          />
                          <Routes />
                        </Div100vh>
                      </SnackContext.Provider>
                    </HeaderContext.Provider>
                  </OrderContext.Provider>
                </CurrentChurchContext.Provider>
              </AppContext.Provider>
            </CurrentUserContext.Provider>
          </UserDialogContext.Provider>
        </IsAuthenticatedContext.Provider>
      </Router>
    )
  );
}

export default App;
