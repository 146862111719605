import {
  ADD_SEAT,
  REMOVE_SEAT,
  UPDATE_CURRENTUSER,
  CREATE_BOOKING,
} from "../actionTypes";
import { v1 as uuid } from "uuid";

export function seatsReducer(state, action) {
  const celebrationId = action.payload.celebrationId;

  switch (action.type) {
    case CREATE_BOOKING:
      return {
        ...state,
        userBookings: {
          ...state.userBookings,
          [uuid()]: action.payload.booking,
        },
      };
    case ADD_SEAT:
      return {
        ...state,
        userTotalSeats: state.userTotalSeats + 1,
        celebrations: {
          ...state.celebrations,
          [celebrationId]: {
            ...state.celebrations[celebrationId],
            availableSeats:
              state.celebrations[celebrationId].availableSeats - 1,
          },
        },
      };
    case REMOVE_SEAT:
      return {
        ...state,
        userTotalSeats: state.userTotalSeats - 1,
        celebrations: {
          ...state.celebrations,
          [celebrationId]: {
            ...state.celebrations[celebrationId],
            availableSeats:
              state.celebrations[celebrationId].availableSeats + 1,
          },
        },
      };
    case UPDATE_CURRENTUSER:
      const currentUser = action.payload.currentUser;
      return {
        ...state,
        currentUser,
      };
    default:
      return;
  }
}
