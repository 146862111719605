import React, { useState } from "react";
import {
  Paper,
  Grid,
  makeStyles,
  Typography,
  TextField,
  Switch,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  bookedFor: {
    paddingTop: theme.spacing(1),
  },
  switchLabel: {
    color: theme.palette.text.secondary,
  },
}));

export default function BookedFor({ bookedFor, setBookedFor }) {
  const classes = useStyles();
  const [bookedForOpen, setBookedForOpen] = useState(false);
  function handleChange(e) {
    setBookedFor(e.target.value);
  }
  function handleToggle() {
    setBookedForOpen((bookedForOpen) => !bookedForOpen);
  }
  return (
    <Paper className={classes.root} elevation={6}>
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="stretch"
      >
        <Grid item>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="body2" className={classes.switchLabel}>
                {" "}
                Réserver pour un autre
              </Typography>
            </Grid>
            <Grid item>
              <Switch onChange={handleToggle} checked={bookedForOpen} />
            </Grid>
          </Grid>
        </Grid>
        <Grid hidden={!bookedForOpen} item className={classes.bookedFor}>
          <TextField
            fullWidth
            label="Pour"
            variant="outlined"
            size="small"
            value={bookedFor}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
