import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { IsAuthenticatedContext } from "../App";

export default function AuthenticatedRoute({ children, ...rest }) {
  const { isAuthenticated } = useContext(IsAuthenticatedContext);
  return (
    <Route {...rest}>
      {isAuthenticated ? children : <Redirect to={`/settings`} />}
    </Route>
  );
}
