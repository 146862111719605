import React from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Button,
  makeStyles,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Spacing from "../components/Spacing";
import dateTransform from "../libs/dateTransform";
import { sumArray } from "../libs/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  info: {
    color: theme.palette.text.secondary,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
export default function BookingCard({
  id: bookingId,
  celebration: {
    id: celebrationId,
    name,
    dateTime,
    church: { id: churchId, name: churchName, availableSeats },
    bookings: { items: bookedSeats },
  },
  seats,
  bookedFor,
  seeButton,
}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { day, date, time } = dateTransform(dateTime);
  const totalSeats = sumArray(bookedSeats);
  const remainingSeats = availableSeats - totalSeats;
  return (
    <div>
      <Paper elevation={4} className={classes.root}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="body1">{name}</Typography>
          </Grid>
          <Grid item className={classes.info}>
            <Typography variant="body2">{`${churchName}, ${date} à ${time}`}</Typography>
          </Grid>
          <Grid item>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item>
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                <Typography>{`${seats} places`}</Typography>
                <Typography
                  className={classes.info}
                  variant="body2"
                >{`Pour ${bookedFor}`}</Typography>
              </Grid>
              <Grid item>
                {seeButton ? (
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={{
                      pathname: `/churches/${churchId}/celebrations/${celebrationId}/bookings/${bookingId}`,
                      seats,
                    }}
                  >
                    Voir
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item>
            <Grid container justify="flex-end">
              <Grid item>
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    marginLeft: "auto",
                  }}
                  variant="body2"
                >{`Il reste ${remainingSeats} places`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
