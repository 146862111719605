import React from "react";
import { Paper, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { makeComparator } from "../libs/utils";

import BookingCard from "../components/BookingCard";

const useStyles = makeStyles((theme) => ({
  bookingCard: {
    marginBottom: theme.spacing(2),
  },
  listItemRoot: {
    padding: theme.spacing(1),
    position: "relative",
  },
  actionBtn: {
    position: "absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

export default function UserBookingsList({ userBookings }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Grid container direction="column" className={classes.root}>
      {userBookings
        .sort(makeComparator("createdAt", "desc"))
        .map(({ id, celebration, seats, bookedFor }) => {
          return (
            <Grid item key={id} className={classes.bookingCard}>
              <BookingCard
                id={id}
                seeButton={true}
                seats={seats}
                celebration={celebration}
                bookedFor={bookedFor}
              />
            </Grid>
          );
        })}
    </Grid>
  );
}
