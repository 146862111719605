import React from "react";
import { Link } from "react-router-dom";
import {
  TextField,
  Grid,
  Button,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.secondary.light,
    height: "100vh",
    margin: 0,
    padding: 0,
  },
}));

export default function Signin() {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <TextField variant="outlined" input="text" />
      </Grid>
    </Grid>
  );
}
