import React, { useContext } from "react";

import { makeStyles } from "@material-ui/core";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import Avatar from "../components/Avatar";
import Seats from "../components/Seats";
import NavigationButton from "../components/NavigationButton";
import Logo from "../components/Logo";
import SettingsLogo from "../components/SettingsLogo";

import { UserDialogContext } from "../App";

const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
  },
  userInfoContainer: {
    display: "flex",
    marginLeft: "auto",
  },
  header: {
    //background: theme.palette.primary.light,
    background: "transparent",
  },
  // toolBar: {
  //   backgroundColor: "transparent",
  // },
}));

export default function Header({
  logo,
  navigation,
  backTo,
  title,
  userInfo,
  seats,
  userName,
  transparent,
  settings,
}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { setUserDialogOpen } = useContext(UserDialogContext);

  function handleAvatarClick() {
    setUserDialogOpen(true);
  }
  const headerStyle = transparent
    ? { background: "transparent" }
    : { background: theme.palette.primary.main };
  return (
    <>
      <AppBar
        elevation={0}
        position="fixed"
        className={classes.header}
        style={headerStyle}
      >
        <Toolbar disableGutters={false} className={classes.toolBar}>
          {logo ? <Logo height={28} /> : null}
          {navigation ? <NavigationButton link={backTo} /> : null}
          {settings && userName ? <SettingsLogo link={"/settings"} /> : null}
          <Typography>{title}</Typography>
          {userInfo ? (
            <div className={classes.userInfoContainer}>
              {seats > 0 ? <Seats count={seats} /> : null}
              {userName ? (
                <Avatar userName={userName} handleClick={handleAvatarClick} />
              ) : null}
            </div>
          ) : null}
        </Toolbar>
      </AppBar>
      {/* <div className={classes.toolbarMargin} /> */}
    </>
  );
}
