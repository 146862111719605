import React from "react";
import { Avatar as AvatarMui, makeStyles, IconButton } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  green: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));

export default function Avatar({ userName, handleClick }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  function initialLetters(userName) {
    if (!userName) {
      return "?";
    }
    const regex = /\b[a-zA-Z]/g;
    const letters = userName.match(regex);
    return letters.length === 1
      ? `${letters[0]}`.toUpperCase()
      : `${letters[0]}${letters[1]}`.toUpperCase();
  }

  return (
    <IconButton size="small" edge="end" onClick={handleClick}>
      <AvatarMui className={classes.green}>
        {initialLetters(userName)}
      </AvatarMui>
    </IconButton>
  );
}
