import React from "react";
import { Settings } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

export default function SettingsLogo({ link }) {
  const theme = useTheme();
  return (
    <div>
      <IconButton
        style={{
          background: theme.palette.primary.main,
          height: "40px",
          width: "40px",
          margin: "0px",
          padding: "0px",
          paddingBottom: "0px",
        }}
        edge="end"
        component={Link}
        to={link}
      >
        <Settings style={{ color: "white" }} />
      </IconButton>
    </div>
  );
}
