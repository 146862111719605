import React, { useState, useEffect } from "react";
import { Paper, Grid, makeStyles, Typography, Button } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  seats: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function BookingButtons({ initialSeats, handleSeatsChange }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  function addSeat() {
    handleSeatsChange(initialSeats + 1);
  }
  function removeSeat() {
    handleSeatsChange(initialSeats - 1);
  }

  return (
    <Paper className={classes.root} elevation={6}>
      <Grid container justify="space-between" alignItems="baseline">
        <Grid item>
          <Typography variant="body2">Places</Typography>
        </Grid>
        <Grid item>
          <Grid container alignItems="baseline">
            <Grid item>
              {" "}
              <Button
                disabled={initialSeats === 0}
                variant="contained"
                size="large"
                onClick={removeSeat}
              >
                -
              </Button>
            </Grid>
            <Grid item>
              <Typography className={classes.seats}>{initialSeats}</Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={addSeat}
              >
                +
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
