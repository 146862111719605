import React from "react";
import { Link } from "react-router-dom";
import { Grid, Button, Typography, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import churchImg from "../assets/church.png";

const useStyles = makeStyles((theme) => ({
  root: {
    //     background: `linear-gradient(transparent, ${theme.palette.primary.dark}), url(${churchImg})`,
    //     backgroundSize: "cover",
    //     backgroundPosition: "center",
    background: `url(${churchImg})`,
    width: "100vw",
    //height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    //height: "100vh",
    margin: 0,
    padding: 0,
  },
  btnContainer: {
    position: "absolute",
    bottom: theme.spacing(4),
    width: "100vw",
  },
  btn: {
    margin: "auto",
  },
}));

export default function Home() {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.root}>
      <Grid container className={classes.btnContainer} justify="center">
        <Grid item>
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            component={Link}
            to="/celebrations"
          >
            Réserver des places
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
