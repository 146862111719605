import React from "react";
import Div100vh from "react-div-100vh";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    background: "red",
  },
});

export default function Test() {
  const classes = useStyles();
  return (
    <Div100vh className={classes.root}>
      <div> salut</div>
    </Div100vh>
  );
}
