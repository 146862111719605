import React from "react";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Spacing from "../components/Spacing";

export default function Admin({ setAdminDialogOpen, adminUser, onLogout }) {
  console.log(adminUser);
  const theme = useTheme();
  const title = adminUser ? "Se déconnecter" : "Administration";
  const body = adminUser
    ? ""
    : "Connectez vous pour accéder aux fonctionnalités d'administration";
  const buttonText = adminUser ? "déconnexion" : "connexion";

  const button = adminUser ? (
    <Button variant="contained" color="secondary" fullWidth onClick={onLogout}>
      {" "}
      Déconnexion
    </Button>
  ) : (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      onClick={() => setAdminDialogOpen(true)}
      size="small"
    >
      {buttonText}
    </Button>
  );
  return (
    <Paper elevation={4} style={{ padding: theme.spacing(1) }}>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item>
          <Spacing height={2} />
        </Grid>
        <Grid item>
          <Typography variant="body2">{body}</Typography>
        </Grid>
        <Grid item>
          <Spacing height={2} />
        </Grid>
        <Grid item>{button}</Grid>
      </Grid>
    </Paper>
  );
}
