import moment from "moment";
export default function dateTransform(dt) {
  const _dateTime = dt.match(
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}/g
  );
  const dateTime = _dateTime ? _dateTime[0] : "2020-01-01T00:00:00+00:00";
  const weekDayArray = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];
  const d = moment(dateTime);
  const date = d.format("DD/MM/YYYY");
  const time = d.format("HH:mm");
  const day = weekDayArray[d.day()];

  return { day, date, time };
}
