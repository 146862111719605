import React, { useContext, useEffect, useState } from "react";
import { Grid, makeStyles, Button } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import { useTheme } from "@material-ui/styles";
import { Connect } from "aws-amplify-react";
import { graphqlOperation, API } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { userBookings } from "../graphql/customs";
import * as api from "../api/api";
import { CurrentUserContext, HeaderContext, OrderContext } from "../App";

import Spacing from "../components/Spacing";
import UserBookingsList from "../components/UserBookingsList";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    padding: theme.spacing(1),
    //width: `calc(100vw - ${theme.spacing(4)}px)`,
  },
  bookingsListContainer: {
    height: "400px",
    overflow: "auto",
  },
  bookingButtonContainer: {
    position: "fixed",
    bottom: theme.spacing(4),
    width: `calc(100vw - ${theme.spacing(2)}px)`,
  },
}));

function UserBookings(props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { currentUser } = useContext(CurrentUserContext);
  const { header, setHeader } = useContext(HeaderContext);
  const { order } = useContext(OrderContext);
  const [userBookings, setUserBookings] = useState();

  useEffect(() => {
    async function getBookings() {
      const bookings = await api.getUserBookings({ userId: currentUser.id });
      setUserBookings(bookings);
    }
    getBookings();
    setHeader((header) => ({
      ...header,
      transparent: false,
      logo: false,
      title: "Vos réservations",
      navigation: true,
      backTo: "/",
      settings: false,
      userInfo: true,
    }));
  }, [header.userName, order]);

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item>
        <Spacing height={8} />
      </Grid>
      <Grid item>
        {userBookings ? <UserBookingsList userBookings={userBookings} /> : null}

        {/* <Connect query={graphqlOperation(userBookings, { id: currentUser.id })}>
          {({ data, loading, errors }) => {
            if (loading) {
              return <div>Loading...</div>;
            }
            if (!data.getUser) return;
            return (
              <UserBookingsList userBookings={data.getUser.bookings.items} />
            );
          }}
        </Connect> */}
      </Grid>
      <Grid item>
        <Spacing height={12} />
      </Grid>
      <Grid item>
        <Grid
          container
          justify="center"
          className={classes.bookingButtonContainer}
        >
          <Button
            size="large"
            color="primary"
            variant="contained"
            fullWidth
            component={Link}
            to="/churches"
          >
            Nouvelle réservation
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default withRouter(UserBookings);
