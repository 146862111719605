import React from "react";
import { Paper, Grid, Typography, ThemeProvider } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import dateTransform from "../libs/dateTransform";

export default function AttendeeCard({ bookedFor, seats, bookedBy, bookedAt }) {
  const { date, hour } = bookedAt
    ? dateTransform(bookedAt)
    : { date: "", hour: "" };
  const theme = useTheme();
  return (
    <Paper elevation={4} style={{ padding: `${theme.spacing(1)}px` }}>
      <Grid container direction="column">
        <Grid item>
          <Grid container>
            <Grid item>{bookedFor}</Grid>
            <Grid item>
              <Typography
                variant="body2"
                style={{
                  color: theme.palette.primary.main,
                  marginLeft: theme.spacing(1),
                }}
              >{`( x${seats} )`}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            style={{ color: theme.palette.text.secondary }}
          >{`Réservé par ${bookedBy}`}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
