import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import logo from "../assets/logo_home.svg";

export default function Logo({ height }) {
  return (
    <div>
      <img style={{ height: `${height}px` }} alt="company logo" src={logo} />
    </div>
  );
}
