import React, { useContext, useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Spacing from "../components/Spacing";
import Reset from "../components/Reset";
import { Auth } from "aws-amplify";

import Admin from "../components/Admin";
import AdminSigninModal from "../containers/AdminSigninModal";

import {
  HeaderContext,
  CurrentUserContext,
  IsAuthenticatedContext,
} from "../App";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    padding: theme.spacing(1),
  },
}));

export default function Settings() {
  const [adminDialogOpen, setAdminDialogOpen] = useState(false);
  const classes = useStyles();
  const { header, setHeader } = useContext(HeaderContext);
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const { setIsAuthenticated, isAuthenticated } = useContext(
    IsAuthenticatedContext
  );

  useEffect(() => {
    setHeader((header) => ({
      ...header,
      logo: true,
      settings: false,
      transparent: true,
      title: "",
      userInfo: false,
      navigation: false,
    }));
  }, []);

  function handleReset() {
    localStorage.clear();
    setCurrentUser({});
  }
  async function handleLogout() {
    await Auth.signOut();
    setIsAuthenticated(false);
  }
  return (
    <>
      <AdminSigninModal open={adminDialogOpen} setOpen={setAdminDialogOpen} />
      <Grid container direction="column" className={classes.root}>
        <Grid item>
          <Spacing height={8} />
        </Grid>
        <Grid item>
          <Admin
            adminUser={isAuthenticated}
            setAdminDialogOpen={setAdminDialogOpen}
            onLogout={handleLogout}
          />
        </Grid>
        <Grid item>
          <Spacing height={1} />
        </Grid>
        <Grid item>
          <Reset onReset={handleReset} />
        </Grid>
      </Grid>
    </>
  );
}
