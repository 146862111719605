export const userBookings = `query UserBookings($id: ID!) {
    getUser(id: $id) {
      bookings {
          items {
              id
              seats
              bookedFor
              celebration {
                  id
                  name
                  dateTime
                  church {
                      id
                      name
                      availableSeats
                  }
                  bookings {
                      items {
                          seats
                      }
                  }
              }
          }
      }
    }
    }`;

export const listChurchs = /* GraphQL */ `
  query ListChurchs(
    $filter: ModelChurchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChurchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        name
        address
        availableSeats
        celebrations {
          items {
            name
          }
        }
        churchSubs {
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const GetBooking = `query GetBooking($id:ID!){
    getBooking(id:$id){
        id
        seats
        bookedFor
        celebration {
            id
            name
            dateTime
            church {
                id
                name
                availableSeats
            }
            bookings {
                items {
                    seats
                }
            }
        }
    }
}`;

export const GetCelebration = `query GetCelebration($id:ID!){
    getCelebration(id:$id){
        id
        name
        dateTime
        church {
            name
            availableSeats
        }
        bookings {
            items {
                seats
            }
        }
    }
}`;

export const getCelebrationAttendees = `query GetCelebration($id:ID!){
    getCelebration(id:$id){
        bookings {
            items {
                id
                seats
                bookedFor
                createdAt
                user { name }
            }
        }
    }
}`;

export const getChurch = `query GetChurch($id:ID!){
    getChurch(id:$id){
        id
        name
        celebrations {
            items {
                id
                name
                dateTime
                church {
                    id
                    availableSeats
                }
                bookings {
                    items {
                        seats
                    }
                }
            }
        }
    }
}`;
