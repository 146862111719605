import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import { Grid, Button, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import ButtonArrow from "../components/ButtonArrow";
import Spacing from "../components/Spacing";
import LogoHome from "../components/LogoHome";

import { CurrentUserContext } from "../App";
import { UserDialogContext } from "../App";
import { HeaderContext } from "../App";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    width: "100vw",
    position: "fixed",
    bottom: theme.spacing(4),
  },
  bookButton: {
    borderRadius: 50,
    width: `calc(100vw - ${theme.spacing(4)}px)`,
  },
}));

export default function LandingPage() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { currentUser } = useContext(CurrentUserContext);
  const { setUserDialogOpen } = useContext(UserDialogContext);
  const { setHeader } = useContext(HeaderContext);

  const handleSubmit = () => {
    if (!currentUser.name) {
      setUserDialogOpen(true);
    }
  };

  useEffect(() => {
    setHeader((oldHeader) => ({
      ...oldHeader,
      navigation: false,
      backTo: "/",
      userInfo: true,
      logo: false,
      title: "",
      avatar: true,
      transparent: true,
      settings: true,
      userName: currentUser?.name,
    }));
  }, []);

  return (
    <div>
      <Grid
        container
        direction="column"
        style={{ width: "100vw" }}
        alignItems="center"
      >
        <Grid item>
          <Spacing height={15} />
        </Grid>
        <Grid item>
          <LogoHome height={300} />
        </Grid>
      </Grid>
      <Grid container className={classes.buttonContainer} justify="center">
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          onClick={handleSubmit}
          className={classes.bookButton}
          component={Link}
          to="/churches"
        >
          <span style={{ marginRight: 10 }}> Réserver une messe </span>
          <ButtonArrow height={15} width={15} fill="white" />
        </Button>
      </Grid>
    </div>
  );
}
