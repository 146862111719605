import React from "react";
import { Link } from "react-router-dom";
import { IconButton, Badge } from "@material-ui/core";
import { EventSeat } from "@material-ui/icons";

export default function Seats({ count }) {
  return (
    <IconButton
      aria-label="show 17 new notifications"
      component={Link}
      to="/bookings"
    >
      <Badge badgeContent={count} color="error">
        <EventSeat style={{ color: "white" }} />
      </Badge>
    </IconButton>
  );
}
