import React, { useContext, useEffect, useState } from "react";
import { Grid, makeStyles, Button } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import { useTheme } from "@material-ui/styles";

import moment from "moment";

import { graphqlOperation, API } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

import * as api from "../api/api";
import { GetBooking, GetCelebration } from "../graphql/customs";
import {
  CurrentUserContext,
  CurrentChurchContext,
  OrderContext,
  SnackContext,
} from "../App";

import Spacing from "../components/Spacing";
import BookingCard from "../components/BookingCard";
import BookingButtons from "../components/BookingButtons";
import BookedFor from "../components/BookedFor";

import { HeaderContext } from "../App";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  bookingButtonContainer: {
    position: "fixed",
    bottom: theme.spacing(4),
    width: `calc(100vw - ${theme.spacing(4)}px)`,
  },
}));

function Booking(props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { currentUser } = useContext(CurrentUserContext);
  const { currentChurch } = useContext(CurrentChurchContext);
  const { setOrder } = useContext(OrderContext);
  const { setHeader } = useContext(HeaderContext);
  const celebrationId = props.match.params.celebrationId;
  const bookingId = props.match.params.bookingId;
  const churchId = props.match.params.churchId;
  const [seats, setSeats] = useState(1);
  const [bookedFor, setBookedFor] = useState(currentUser.name);
  const [celebration, setCelebration] = useState();
  const { setSnack } = useContext(SnackContext);

  function handleDelete() {
    api.deleteBooking({ bookingId });
    setSnack({ open: true, type: "DELETE_BOOKING" });
    setOrder((order) => order + 1); // to force App update
  }
  async function handleNewClick() {
    const newBookingInputs = {
      userId: currentUser.id,
      celebrationId,
      seats,
      bookedFor,
      createdAt: moment().format(),
    };
    if (currentUser.id && celebrationId) {
      console.log(newBookingInputs);
      try {
        const newBooking = await API.graphql(
          graphqlOperation(mutations.createBooking, { input: newBookingInputs })
        );
        console.log("new Booking: ", newBooking);
        setOrder((order) => order + 1);
        setSnack({ open: true, type: "NEW_BOOKING" });
      } catch (error) {
        console.log(error);
      }
    } else {
      return;
    }
  }
  async function handleUpdateClick() {
    try {
      console.log("updating seats with :", seats);
      await API.graphql(
        graphqlOperation(mutations.updateBooking, {
          input: {
            id: bookingId,
            bookedFor,
            seats,
            createdAt: moment().format(),
          },
        })
      );
      console.log("Booking updated");
      setSnack({ open: true, type: "UPDATED_BOOKING" });
    } catch (e) {
      console.log(e);
    }
  }
  async function _getBooking() {
    const resp = await API.graphql(
      graphqlOperation(GetBooking, { id: bookingId })
    );
    setSeats(resp.data.getBooking.seats);
    setBookedFor(resp.data.getBooking.bookedFor);
    setCelebration(resp.data.getBooking.celebration);
  }
  async function _getCelebration() {
    const celebration = await api.getCelebration({ celebrationId });
    setCelebration(celebration);
  }
  function handleSeatsChange(newSeats) {
    setSeats(newSeats);
  }
  useEffect(() => {
    if (bookingId) {
      _getBooking();
    } else {
      _getCelebration();
    }
    setHeader((header) => ({
      ...header,
      logo: false,
      title: "Votre réservation",
      navigation: true,
      backTo: bookingId ? "/bookings" : `/churches/${churchId}/celebrations`,
      userInfo: true,
    }));
  }, []);

  return (
    <div className={classes.root}>
      <Spacing height={8} />
      <Grid container direction="column" alignItems="stretch">
        {bookingId ? (
          <>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                component={Link}
                to="/bookings"
                onClick={handleDelete}
              >
                Supprimer
              </Button>
            </Grid>
            <Grid item>
              <Spacing height={1} />
            </Grid>
          </>
        ) : null}
        <Grid item>
          {celebration ? (
            <BookingCard
              celebration={celebration}
              seats={seats}
              bookedFor={bookedFor}
            />
          ) : (
            "loading..."
          )}
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="stretch"
        className={classes.bookingButtonContainer}
      >
        <Grid item>
          <BookedFor bookedFor={bookedFor} setBookedFor={setBookedFor} />
        </Grid>
        <Grid item>
          <BookingButtons
            initialSeats={seats}
            handleSeatsChange={handleSeatsChange}
          />
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={bookingId ? handleUpdateClick : handleNewClick}
            component={Link}
            to="/bookings"
          >
            {bookingId ? "Mettre à jour" : "Confirmer"}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
export default withRouter(Booking);
