import React from "react";
import AttendeeCard from "../components/AttendeeCard";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(1),
  },
}));

export default function AttendeeList({ bookings, bookedForFilter }) {
  const classes = useStyles();
  const filteredBookings = bookings.filter((booking) => {
    return booking?.bookedFor
      .toLowerCase()
      .includes(bookedForFilter.toLowerCase());
  });
  return (
    <div>
      {filteredBookings.map(
        ({ id, seats, bookedFor, user: { name }, createdAt: bookedAt }) => {
          return (
            <div key={id} className={classes.card}>
              <AttendeeCard
                seats={seats}
                bookedFor={bookedFor}
                bookedAt={bookedAt}
                bookedBy={name}
              />
            </div>
          );
        }
      )}
      ;
    </div>
  );
}
