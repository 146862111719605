import React from "react";
import { Link } from "react-router-dom";

import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";

import ButtonArrow from "../components/ButtonArrow";
import Spacing from "../components/Spacing";

import saintPothin from "../assets/saintPothin.jpg";
import immaculee from "../assets/immaculee.jpg";
import fictiveChurch from "../assets/fictiveChurch.jpg";

const useStyles = makeStyles({
  root: {
    // maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function ChurchCard({
  id,
  name,
  address,
  onClick,
  celebrations,
}) {
  const classes = useStyles();
  console.log(celebrations);
  const celebrationsCount = celebrations?.length;
  function getImage() {
    switch (id) {
      case "e6e319b7-8a70-4a10-9294-5a76dd00ad4e":
        return saintPothin;
      case "d8011b1d-b059-41bf-a3e9-e4fffce5d832":
        return immaculee;
      default:
        return fictiveChurch;
    }
  }
  function handleClick() {
    onClick(id, name);
  }
  return (
    <Card className={classes.root}>
      <CardActionArea component={Link} to={`/churches/${id}/celebrations`}>
        <CardMedia
          className={classes.media}
          image={getImage()}
          title="Saint Pothin"
          onClick={handleClick}
        />
        <CardContent>
          <Typography gutterBottom={false} variant="h6" component="h2">
            {name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {address}
          </Typography>
          {celebrationsCount > 0 ? (
            <>
              <Spacing height={1} />
              <Grid container justify="flex-end">
                <Grid item>
                  <Button variant="contained" color="primary" size="small">
                    <span
                      style={{ marginRight: 10 }}
                    >{`${celebrationsCount} messes`}</span>
                    <ButtonArrow height={15} width={15} fill="white" />
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : null}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
