import React, { useState, useContext } from "react";
import { v1 as uuid } from "uuid";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as api from "../api/api";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import Spacing from "../components/Spacing";

import { CurrentUserContext, SnackContext } from "../App";

export default function SigninModal({ open, setOpen }) {
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const [userName, setUserName] = useState(
    currentUser.name ? currentUser.name : ""
  );
  const { snack, setSnack } = useContext(SnackContext);

  function validUserName(userName) {
    return userName !== "";
  }

  function onClose() {
    setOpen(false);
  }
  async function onCreate() {
    if (validUserName(userName)) {
      const currentUser = {
        id: uuid(),
        name: userName,
      };
      setCurrentUser(currentUser);
      if (userName !== "") {
        try {
          await API.graphql(
            graphqlOperation(mutations.createUser, { input: currentUser })
          );
          console.log("new user created");
        } catch (e) {
          console.log(e);
        }
      }
      setOpen(false);
      setSnack({ open: true, type: "NEW_USER" });
    } else {
      console.log("invalid user name");
    }
  }
  function onUpdate() {
    if (validUserName(userName)) {
      setCurrentUser((currentUser) => ({ ...currentUser, name: userName }));
      api.updateUser(currentUser);
      setOpen(false);
      setSnack({ open: true, type: "UPDATED_USER" });
    } else {
      console.log("invalid user name");
    }
  }

  function handleUserNameChange(name) {
    setUserName(name);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {currentUser?.id ? "Changez votre identifiant" : "Qui êtes-vous ?"}
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          margin="normal"
          helperText="Antoine, Famille Favreau, Tonio,..."
          label="Identifiant"
          value={userName}
          onChange={({ target: { value } }) => handleUserNameChange(value)}
          fullWidth
        />
        <Spacing height={2} />
        <DialogContentText>
          Ce nom sera utilisé sur vos réservations.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Annuler
        </Button>
        <Button
          variant="contained"
          onClick={currentUser?.id ? onUpdate : onCreate}
          color="primary"
        >
          {currentUser?.id ? "Confirmer" : "Continuer"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
