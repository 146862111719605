import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import {
  TextField,
  Grid,
  Button,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import Spacing from "../components/Spacing";
import { CurrentUserContext } from "../App";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    //height: "100vh",
    margin: 0,
    padding: theme.spacing(2),
    position: "relative",
  },
  fieldContainer: {
    width: `calc(100vw - ${theme.spacing(4)}px)`,
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    width: `calc(100vw - ${theme.spacing(4)}px)`,
    position: "fixed",
    bottom: theme.spacing(4),
  },
  bookBtn: {},
}));

export default function Profile() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const [username, setUsername] = useState(
    currentUser.name ? currentUser.name : ""
  );

  const handleSubmit = () => {
    const newUser = {
      id: currentUser.id,
      name: username,
    };
    setCurrentUser(newUser);
  };

  const handleChange = (name) => {
    setUsername(name);
  };

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      justify="flex-start"
      alignItems="center"
    >
      <Grid item>
        <Spacing height={16} />
      </Grid>
      <Grid item>
        <Typography variant="body1"> Changez votre identifiant : </Typography>
      </Grid>
      <Grid item>
        <Spacing height={10} />
      </Grid>
      <Grid item>
        <div className={classes.fieldContainer}>
          <TextField
            variant="outlined"
            label="Votre identifiant"
            input="text"
            fullWidth
            value={username}
            onChange={({ target: { value } }) => handleChange(value)}
          />
        </div>
        <Typography variant="caption" color="textSecondary">
          {" "}
          Exemple : "Famille Favreau", "Bernard", "Amélie Laurrier",...
        </Typography>
      </Grid>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          component={Link}
          to="/bookings"
          size="large"
          onClick={handleSubmit}
          className={classes.bookBtn}
        >
          Mettre à jour
        </Button>
      </div>
    </Grid>
  );
}
