import { createMuiTheme } from "@material-ui/core/styles";

const brown = "#C9BB9D";
const grey = "#F5F5F5";
const yellow = "#FFEB3B";
const orange = "#FFA000";
const green = "#00897b";
//const brownLight = "#C9BB9E";
//const brownDark = "#61564D";

export default createMuiTheme({
  palette: {
    common: {
      grey: `${grey}`,
    },
    primary: {
      main: `${green}`,
    },
    secondary: {
      main: `${orange}`,
    },
  },
  typography: {
    htmlFontSize: 14,
    fontSize: 12,
    caption: {
      fontSize: "0.75rem",
      lineHeight: 1,
    },
  },
});
