import React, { useContext, useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useTheme } from "@material-ui/styles";
import { Connect } from "aws-amplify-react";
import { graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import { getChurch } from "../graphql/customs";
import * as api from "../api/api";

import Spacing from "../components/Spacing";
import CelebrationsList from "../components/CelebrationsList";

import { HeaderContext, IsAuthenticatedContext } from "../App";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

function Celebrations(props) {
  const { setHeader } = useContext(HeaderContext);
  const { isAuthenticated } = useContext(IsAuthenticatedContext);
  const [church, setChurch] = useState();
  const theme = useTheme();
  const classes = useStyles(theme);
  const churchId = props.match.params.churchId;

  useEffect(() => {
    async function getChurch() {
      const c = await api.getChurch({ churchId });
      setChurch(c);
    }
    getChurch();
    setHeader((header) => ({
      ...header,
      title: church?.name,
      logo: false,
      navigation: true,
      backTo: "/churches",
    }));
  }, [church?.name]);

  return (
    <div>
      <Spacing height={8} />
      <Grid
        container
        direction="column"
        alignItems="stretch"
        className={classes.root}
      >
        <Grid item>
          {church ? (
            <CelebrationsList
              celebrations={church?.celebrations.items}
              admin={isAuthenticated}
            />
          ) : (
            "Loading..."
          )}
        </Grid>
      </Grid>
    </div>
  );
}
export default withRouter(Celebrations);
