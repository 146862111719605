import React from "react";
import { Grid } from "@material-ui/core";

import CreateChurchForm from "../components/CreateChurchForm";
import Spacing from "../components/Spacing";

export default function CreateChurch() {
  function onSubmit(church) {
    console.log(church);
  }
  return (
    <Grid container direction="column">
      <Grid item>
        <Spacing height={8} />
      </Grid>
      <Grid item>
        <CreateChurchForm onSubmit={onSubmit} />
      </Grid>
    </Grid>
  );
}
