import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import * as customs from "../graphql/customs";
import Churches from "../containers/Churches";

export const updateUser = async (user) => {
  await API.graphql(
    graphqlOperation(mutations.updateUser, {
      input: {
        id: user.id,
        name: user.name,
      },
    })
  );
  console.log("User updated with success!");
};

export const listChurchs = async () => {
  const churches = await API.graphql(
    graphqlOperation(customs.listChurchs, {
      filter: {
        status: {
          ne: "INACTIVE",
        },
      },
    })
  );
  return churches.data.listChurchs.items;
};

export const getUserBookings = async ({ userId }) => {
  const resp = await API.graphql(
    graphqlOperation(customs.userBookings, { id: userId })
  );
  return resp.data.getUser.bookings.items;
};

export const deleteBooking = async ({ bookingId }) => {
  await API.graphql(
    graphqlOperation(mutations.deleteBooking, { input: { id: bookingId } })
  );
  console.log("Booking deleted with success!");
};

export const getCelebration = async ({ celebrationId }) => {
  const resp = await API.graphql(
    graphqlOperation(customs.GetCelebration, { id: celebrationId })
  );
  return resp.data.getCelebration;
};

export const getChurch = async ({ churchId }) => {
  const resp = await API.graphql(
    graphqlOperation(customs.getChurch, { id: churchId })
  );
  return resp.data.getChurch;
};

export const listAttendees = async ({ celebrationId }) => {
  try {
    const resp = await API.graphql(
      graphqlOperation(customs.getCelebrationAttendees, { id: celebrationId })
    );
    return resp.data.getCelebration.bookings.items;
  } catch (e) {
    console.log(e);
    return e.data.getCelebration.bookings.items;
  }
};
