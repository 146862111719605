import React from "react";
import { Paper, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { Link } from "react-router-dom";
import dateTransform from "../libs/dateTransform";
import { sumArray, makeComparator } from "../libs/utils";
import Spacing from "../components/Spacing";

const useStyles = makeStyles((theme) => ({
  churchItemContainer: {
    marginBottom: theme.spacing(2),
  },
  listItemRoot: {
    padding: theme.spacing(1),
    position: "relative",
  },
  actionBtn: {
    position: "absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

function CelebrationListItem({
  id,
  name,
  dateTime,
  churchId,
  seats,
  onSubscribe,
  admin,
}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { day, date, time } = dateTransform(dateTime);

  function handleChange() {
    onSubscribe(id);
  }

  return (
    <Paper elevation={3} className={classes.listItemRoot}>
      <Typography variant="body1">{name}</Typography>
      <Typography
        variant="body2"
        style={{ color: theme.palette.text.secondary }}
      >{`${date} à ${time}`}</Typography>
      <Typography
        variant="body2"
        style={{ color: theme.palette.primary.main }}
      >{`Il reste ${seats} places`}</Typography>
      <Button
        size="small"
        className={classes.actionBtn}
        variant="contained"
        color="primary"
        component={Link}
        to={`/churches/${churchId}/celebrations/${id}/newbooking`}
      >
        Réserver
      </Button>
      {admin ? (
        <Grid item>
          <Spacing height={2} />
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`/celebrations/${id}/attendees`}
          >
            {" "}
            Voir
          </Button>
        </Grid>
      ) : null}
    </Paper>
  );
}

export default function CelebrationsList({ celebrations, admin }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Grid container direction="column" className={classes.root}>
      {celebrations
        .sort(makeComparator("dateTime"))
        .map(
          ({
            id,
            name,
            dateTime,
            church: { id: churchId, availableSeats },
            bookings: { items: bookedSeats },
          }) => {
            const remainingSeats = availableSeats - sumArray(bookedSeats);
            return (
              <Grid item key={id} className={classes.churchItemContainer}>
                <CelebrationListItem
                  admin={admin}
                  id={id}
                  name={name}
                  dateTime={dateTime}
                  churchId={churchId}
                  seats={remainingSeats}
                />
              </Grid>
            );
          }
        )}
    </Grid>
  );
}
