/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createChurch = /* GraphQL */ `
  mutation CreateChurch(
    $input: CreateChurchInput!
    $condition: ModelChurchConditionInput
  ) {
    createChurch(input: $input, condition: $condition) {
      id
      status
      name
      address
      availableSeats
      celebrations {
        items {
          id
          status
          name
          dateTime
          churchId
        }
        nextToken
      }
      churchSubs {
        items {
          id
          churchId
          userId
        }
        nextToken
      }
    }
  }
`;
export const updateChurch = /* GraphQL */ `
  mutation UpdateChurch(
    $input: UpdateChurchInput!
    $condition: ModelChurchConditionInput
  ) {
    updateChurch(input: $input, condition: $condition) {
      id
      status
      name
      address
      availableSeats
      celebrations {
        items {
          id
          status
          name
          dateTime
          churchId
        }
        nextToken
      }
      churchSubs {
        items {
          id
          churchId
          userId
        }
        nextToken
      }
    }
  }
`;
export const deleteChurch = /* GraphQL */ `
  mutation DeleteChurch(
    $input: DeleteChurchInput!
    $condition: ModelChurchConditionInput
  ) {
    deleteChurch(input: $input, condition: $condition) {
      id
      status
      name
      address
      availableSeats
      celebrations {
        items {
          id
          status
          name
          dateTime
          churchId
        }
        nextToken
      }
      churchSubs {
        items {
          id
          churchId
          userId
        }
        nextToken
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      churchSubs {
        items {
          id
          churchId
          userId
        }
        nextToken
      }
      bookings {
        items {
          id
          userId
          celebrationId
          seats
          bookedFor
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      churchSubs {
        items {
          id
          churchId
          userId
        }
        nextToken
      }
      bookings {
        items {
          id
          userId
          celebrationId
          seats
          bookedFor
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      churchSubs {
        items {
          id
          churchId
          userId
        }
        nextToken
      }
      bookings {
        items {
          id
          userId
          celebrationId
          seats
          bookedFor
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const createChurchSub = /* GraphQL */ `
  mutation CreateChurchSub(
    $input: CreateChurchSubInput!
    $condition: ModelChurchSubConditionInput
  ) {
    createChurchSub(input: $input, condition: $condition) {
      id
      churchId
      userId
      church {
        id
        status
        name
        address
        availableSeats
        celebrations {
          nextToken
        }
        churchSubs {
          nextToken
        }
      }
      user {
        id
        name
        churchSubs {
          nextToken
        }
        bookings {
          nextToken
        }
      }
    }
  }
`;
export const updateChurchSub = /* GraphQL */ `
  mutation UpdateChurchSub(
    $input: UpdateChurchSubInput!
    $condition: ModelChurchSubConditionInput
  ) {
    updateChurchSub(input: $input, condition: $condition) {
      id
      churchId
      userId
      church {
        id
        status
        name
        address
        availableSeats
        celebrations {
          nextToken
        }
        churchSubs {
          nextToken
        }
      }
      user {
        id
        name
        churchSubs {
          nextToken
        }
        bookings {
          nextToken
        }
      }
    }
  }
`;
export const deleteChurchSub = /* GraphQL */ `
  mutation DeleteChurchSub(
    $input: DeleteChurchSubInput!
    $condition: ModelChurchSubConditionInput
  ) {
    deleteChurchSub(input: $input, condition: $condition) {
      id
      churchId
      userId
      church {
        id
        status
        name
        address
        availableSeats
        celebrations {
          nextToken
        }
        churchSubs {
          nextToken
        }
      }
      user {
        id
        name
        churchSubs {
          nextToken
        }
        bookings {
          nextToken
        }
      }
    }
  }
`;
export const createCelebration = /* GraphQL */ `
  mutation CreateCelebration(
    $input: CreateCelebrationInput!
    $condition: ModelCelebrationConditionInput
  ) {
    createCelebration(input: $input, condition: $condition) {
      id
      status
      name
      dateTime
      churchId
      church {
        id
        status
        name
        address
        availableSeats
        celebrations {
          nextToken
        }
        churchSubs {
          nextToken
        }
      }
      bookings {
        items {
          id
          userId
          celebrationId
          seats
          bookedFor
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const updateCelebration = /* GraphQL */ `
  mutation UpdateCelebration(
    $input: UpdateCelebrationInput!
    $condition: ModelCelebrationConditionInput
  ) {
    updateCelebration(input: $input, condition: $condition) {
      id
      status
      name
      dateTime
      churchId
      church {
        id
        status
        name
        address
        availableSeats
        celebrations {
          nextToken
        }
        churchSubs {
          nextToken
        }
      }
      bookings {
        items {
          id
          userId
          celebrationId
          seats
          bookedFor
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCelebration = /* GraphQL */ `
  mutation DeleteCelebration(
    $input: DeleteCelebrationInput!
    $condition: ModelCelebrationConditionInput
  ) {
    deleteCelebration(input: $input, condition: $condition) {
      id
      status
      name
      dateTime
      churchId
      church {
        id
        status
        name
        address
        availableSeats
        celebrations {
          nextToken
        }
        churchSubs {
          nextToken
        }
      }
      bookings {
        items {
          id
          userId
          celebrationId
          seats
          bookedFor
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      userId
      celebrationId
      seats
      bookedFor
      user {
        id
        name
        churchSubs {
          nextToken
        }
        bookings {
          nextToken
        }
      }
      celebration {
        id
        status
        name
        dateTime
        churchId
        church {
          id
          status
          name
          address
          availableSeats
        }
        bookings {
          nextToken
        }
      }
      createdAt
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      userId
      celebrationId
      seats
      bookedFor
      user {
        id
        name
        churchSubs {
          nextToken
        }
        bookings {
          nextToken
        }
      }
      celebration {
        id
        status
        name
        dateTime
        churchId
        church {
          id
          status
          name
          address
          availableSeats
        }
        bookings {
          nextToken
        }
      }
      createdAt
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      id
      userId
      celebrationId
      seats
      bookedFor
      user {
        id
        name
        churchSubs {
          nextToken
        }
        bookings {
          nextToken
        }
      }
      celebration {
        id
        status
        name
        dateTime
        churchId
        church {
          id
          status
          name
          address
          availableSeats
        }
        bookings {
          nextToken
        }
      }
      createdAt
    }
  }
`;
