import React, { useState, useContext } from "react";
import { v1 as uuid } from "uuid";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as api from "../api/api";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Input,
  InputAdornment,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Spacing from "../components/Spacing";
import { Auth } from "aws-amplify";
import { IsAuthenticatedContext } from "../App";

const useStyles = makeStyles((theme) => ({
  textField: {
    background: "white",
  },
}));
export default function AdminSigninModal({ open, setOpen }) {
  const { setIsAuthenticated } = useContext(IsAuthenticatedContext);
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");

  function validateForm() {
    return email.length > 0 && pwd.length > 0;
  }
  function onClose() {
    setOpen(false);
  }
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await Auth.signIn(email, pwd);
      setIsAuthenticated(true);
    } catch (e) {
      console.log(e);
    }
    setOpen(false);
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Se connecter</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          variant="outlined"
          margin="normal"
          label="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          marge="normal"
          size="small"
          InputProps={{ className: classes.textField }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          label="mot de passe"
          value={pwd}
          onChange={(e) => setPwd(e.target.value)}
          fullWidth
          marge="normal"
          size="small"
          InputProps={{
            className: classes.textField,
          }}
        />
        <Spacing height={8} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Annuler
        </Button>
        <Button variant="contained" onClick={handleSubmit} color="primary">
          Connexion
        </Button>
      </DialogActions>
    </Dialog>
  );
}
