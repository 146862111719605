import React, { useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import Spacing from "../components/Spacing";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function message(type) {
  switch (type) {
    case "WELCOME":
      return "Bienvenu sur Messeat !";
    case "UPDATED_USER":
      return "Identifiant mis à jour !";
    case "NEW_USER":
      return "Indentifiant créé !";
    case "NEW_BOOKING":
      return "Réservation enregistrée !";
    case "UPDATED_BOOKING":
      return "Réservation mise à jour";
    case "DELETE_BOOKING":
      return "Réservation supprimée !";
    default:
      return;
  }
}

export default function Snack({ open, setSnack, type }) {
  function handleSnackbarClose() {
    setSnack({ open: false, type });
  }
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {message(type)}
        </Alert>
      </Snackbar>
    </div>
  );
}
