import React from "react";
import { Paper, Grid, Typography, Button } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Spacing from "../components/Spacing";

export default function Reset({ onReset }) {
  const theme = useTheme();

  return (
    <Paper elevation={4} style={{ padding: theme.spacing(1) }}>
      <Grid container direction="column">
        <Grid item>
          {" "}
          <Typography variant="h6"> Données locales</Typography>
        </Grid>
        <Grid item>
          <Spacing height={2} />
          <Typography variant="body2">
            {" "}
            Votre identifiant et une clé unique sont enregistrés dans la mémoire
            locale de votre navigateur. <br /> Vous pouvez effacer ces données
            définitivement.
            <br /> Attention, vous perdrez alors toutes les réservations faites
            avec cet identifiant.
          </Typography>
        </Grid>
        <Grid item>
          <Spacing height={2} />
          <Button
            color="primary"
            variant="contained"
            fullWidth
            size="small"
            onClick={onReset}
          >
            Effacer les données
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
