import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Celebrations from "./containers/Celebrations";
import Booking from "./containers/Booking";
import Signin from "./containers/Signin";
import Landing from "./containers/Landing";
import UserBookings from "./containers/UserBookings";
import Profile from "./containers/Profile";
import Churches from "./containers/Churches";
import CreateChurch from "./containers/CreateChurch";
import Settings from "./containers/Settings";
import Test from "./containers/Test";
import Attendees from "./containers/Attendees";
import AuthenticatedRoute from "./components/AuthenticatedRoute";

export default function Routes() {
  return (
    <Switch>
      <AuthenticatedRoute exact path="/celebrations/:celebrationId/attendees">
        <Attendees />
      </AuthenticatedRoute>
      <Route exact path="/home">
        <Home />
      </Route>
      <Route exact path="/settings">
        <Settings />
      </Route>
      <Route exact path="/churches/:churchId/celebrations">
        <Celebrations />
      </Route>
      <Route exact path="/celebrations">
        <Celebrations />
      </Route>
      <Route exact path="/celebrations/:celebrationId/booking">
        <div> Booking </div>
      </Route>
      <Route
        exact
        path="/churches/:churchId/celebrations/:celebrationId/newbooking"
      >
        <Booking />
      </Route>
      <Route exact path="/signin">
        <Signin />
      </Route>
      <Route exact path="/">
        <Landing />
      </Route>
      <Route exact path="/bookings">
        <UserBookings />
      </Route>
      <Route
        exact
        path="/churches/:churchId/celebrations/:celebrationId/bookings/:bookingId"
      >
        <Booking />
      </Route>
      <Route exact path="/profile">
        <Profile />
      </Route>
      <Route exact path="/churches">
        <Churches />
      </Route>
      <Route exact path="/test">
        <Test />
      </Route>
      <Route exact path="/newChurch">
        <CreateChurch />
      </Route>
    </Switch>
  );
}
