import React, { useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";

export default function CreateChurchForm({ onSubmit }) {
  const [church, setChurch] = useState({ name: "", address: "" });

  function handleChange(event) {
    const { name, value } = event.target;
    console.log(event.target.name);
    setChurch((church) => ({ ...church, [name]: value }));
  }

  function handleSubmit() {
    onSubmit(church);
  }
  return (
    <div>
      <Grid container direction="column" style={{ padding: "8px" }}>
        <Grid item>
          <TextField
            label="Nom"
            name="name"
            variant="outlined"
            margin="normal"
            size="small"
            value={church.name}
            onChange={handleChange}
            InputProps={{ style: { background: "white" } }}
          />
          <TextField
            label="Addresse"
            name="address"
            variant="outlined"
            margin="normal"
            size="small"
            value={church.address}
            onChange={handleChange}
            InputProps={{ style: { background: "white" } }}
          />
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {" "}
            Créer{" "}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
