import React, { useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import * as api from "../api/api";
import Spacing from "../components/Spacing";
import SearchBar from "../components/SearchBar";
import AttendeeList from "../components/AttendeeList";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    padding: theme.spacing(1),
  },
}));
function Attendees(props) {
  const classes = useStyles();
  const [attendees, setAttendees] = useState();
  const celebrationId = props.match.params.celebrationId;
  const [bookedForFilter, setBookedForFilter] = useState("");

  async function listAttendees() {
    const attendees = await api.listAttendees({ celebrationId });
    setAttendees(attendees);
  }
  useEffect(() => {
    listAttendees(celebrationId);
  }, []);

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item>
        <Spacing height={8} />
      </Grid>
      <Grid item>
        <SearchBar onChange={setBookedForFilter} value={bookedForFilter} />
      </Grid>
      <Grid item>
        <Spacing height={2} />
      </Grid>
      <Grid item>
        {attendees ? (
          <AttendeeList
            bookings={attendees}
            bookedForFilter={bookedForFilter}
          />
        ) : (
          "Loading ..."
        )}
      </Grid>
    </Grid>
  );
}

export default withRouter(Attendees);
