import React from "react";
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";

export default function NavigationButton({ link }) {
  return (
    <IconButton component={Link} to={link}>
      <ArrowBack style={{ color: "white" }} />
    </IconButton>
  );
}
