import React, { useContext } from "react";
import { Paper, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { Link } from "react-router-dom";
import ButtonArrow from "../components/ButtonArrow";
import { CurrentChurchContext } from "../App";
import ChurchCard from "../components/ChurchCard";

const useStyles = makeStyles((theme) => ({
  churchItemContainer: {
    marginBottom: theme.spacing(2),
  },
  listItemRoot: {
    padding: theme.spacing(1),
    position: "relative",
  },
  actionBtn: {
    position: "absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

function ChurchListItem({ id, name, address }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { setCurrentChurch } = useContext(CurrentChurchContext);

  function handleChurchClick() {
    setCurrentChurch({ id, name });
  }

  return (
    <Paper elevation={3} className={classes.listItemRoot}>
      <Typography variant="body1">{name}</Typography>
      <Typography
        variant="body2"
        style={{ color: theme.palette.text.secondary }}
      >
        {address}
      </Typography>
      <Button
        size="small"
        className={classes.actionBtn}
        variant="contained"
        color="primary"
        component={Link}
        to={`/churches/${id}/celebrations`}
        onClick={handleChurchClick}
      >
        <span style={{ marginRight: 10 }}>Messes</span>
        <ButtonArrow height={15} width={15} fill="white" />
      </Button>
    </Paper>
  );
}

export default function ChurchesList({ churches }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { setCurrentChurch } = useContext(CurrentChurchContext);

  function handleChurchClick(id, name) {
    setCurrentChurch({ id, name });
  }
  return (
    <Grid container direction="column" className={classes.root}>
      {churches.map(
        ({ id, name, address, celebrations: { items: celebs } }) => {
          return (
            <Grid item key={id} className={classes.churchItemContainer}>
              <ChurchCard
                id={id}
                name={name}
                address={address}
                celebrations={celebs}
                onClick={handleChurchClick}
              />
            </Grid>
          );
        }
      )}
    </Grid>
  );
}
