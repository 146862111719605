import React, { useContext, useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { Connect } from "aws-amplify-react";
import { graphqlOperation, API } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as api from "../api/api";

import Spacing from "../components/Spacing";
import ChurchesList from "../components/ChurchesList";
import { HeaderContext } from "../App";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    padding: theme.spacing(1),
  },
}));

export default function Churches() {
  const { header, setHeader } = useContext(HeaderContext);
  const [churches, setChurches] = useState([]);

  useEffect(() => {
    async function listChurchs() {
      const c = await api.listChurchs();
      setChurches(c);
    }
    listChurchs();
    setHeader((oldHeader) => ({
      ...oldHeader,
      logo: true,
      title: "",
      navigation: false,
      backTo: "/",
      avatar: true,
      transparent: false,
      settings: false,
    }));
  }, [header.userName]);

  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div>
      <Spacing height={8} />
      <Grid
        className={classes.root}
        container
        direction="column"
        alignItems="stretch"
      >
        <Grid item>
          <ChurchesList churches={churches} />;
        </Grid>
      </Grid>
    </div>
  );
}
